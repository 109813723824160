<template>
  <v-container class="pt-5">
    <v-btn outlined @click="create_team_dialog = true">
      <v-icon>
        mdi-plus
      </v-icon>
      Create a team
    </v-btn>
    <v-row class="my-5">
      <v-btn
        v-for="site in sites"
        :key="site.path + 'button'"
        outlined
        @click="$router.push(`/tiimi-admin/${site.path}`)"
        class="ml-3"
        :color="path.includes(site.path) ? 'primary' : ''"
      >
        {{ site.text }}
      </v-btn>
    </v-row>
    <router-view></router-view>
    <v-dialog v-model="create_team_dialog" max-width="800">
      <v-card>
        <v-card-title>
          Create a team to tiimi.io
        </v-card-title>
        <v-card-text>
          <v-text-field 
            v-model="team.team_name" 
            placeholder="Team name*"
          ></v-text-field>
          <v-select
            dense
            item-text="sport_name"
            item-value="id"
            :items="sports"
            v-model="team.sport_id"
            placeholder="Choose a sport *"
          >
          </v-select>
          <v-select
            dense
            item-text="league_name"
            item-value="id"
            :items="unarchived_leagues"
            clearable
            v-model="team.league_id"
            placeholder="Choose a league"
          >
          </v-select>
          <v-select
            dense
            item-text="price"
            item-value="id"
            :items="plans"
            v-model="team.plan_id"
            placeholder="Team plan *"
          >
          </v-select>
          <v-text-field
            dense
            v-model="team.initial_admin"
            placeholder="Initial admin email (required) *"
            :counter="300"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="startCreateTeam()" color="primary">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { 
  },
  created() {
    this.initSports()
      .then()
      .catch(e => this.error(e))

    this.initPlans()
    this.initLeagues()
  },
  data: () => ({
    create_team_dialog: false,
    team: {
      team_name: '',
      sport_id: null,
      plan_id: null,
      dont_add_user: true,
      league_id: null,
      initial_admin: ''
    },
    sites: [
      { path: 'leagues', text: 'Leagues' },
      { path: 'sports', text: 'Sports' },
      // { path: 'teams', text: 'Teams' },
    ]
  }),
  methods: {
    startCreateTeam() {
      this.createTeam(this.team)
        .then(() => {
          this.team = {
            team_name: '',
            sport_id: null,
            plan_id: null,
            dont_add_user: true,
            initial_admin: ''
          }
        })
        .catch(e => {
          alert(e)
        })
    },
    ...mapActions('admin', [
      'initLeagues'
    ]),
    ...mapActions([
      'initSports'
    ]),
    ...mapActions('auth', [
      'initPlans'
    ]),
    ...mapActions('team', [
      'createTeam'
    ])
  },
  computed: {
    ...mapGetters([
      'sports'
    ]),
    ...mapGetters('auth', [
      'plans'
    ]),
    ...mapGetters('admin', [
      'leagues',
      'unarchived_leagues'
    ]),
    path() {
      return this.$route.path
    }
  }
}
</script>