<template>
  <div>
    
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  created() {
    const code = this.$route.params.code

    if(!code) {
      this.$router.push('/videos')
      this.error('Invalid link :/')
      return
    }

    this.confirmEmail(code)
      .then((e) => {
        this.initUser(e.token)

        this.$nextTick(() => {
          this.$router.push('/videos')
          this.success('Email confirmed! :)')
        })
      })
      .catch(e => {
        this.$router.push('/videos')
        this.error(e)
      })
  },
  methods: {
    ...mapActions('auth', [
      'confirmEmail'
    ]),
    ...mapActions('user', [
      'initUser'
    ]),
    ...mapActions('noti', [
      'info',
      'error',
      'success'
    ])
  }
}
</script>