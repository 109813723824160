<template>
  <v-card flat class="pa-10">
    <v-select solo dense style="width: 200px;"
      :items="[2022, 2021]"
      v-model="season"
      @change="changeSeason($event)"
    ></v-select>
    <v-row>
      <v-col
        cols="12" lg="3" md="6" sm="12"
        v-for="game in games"
        :key="game.id + 'game'"
      >
        <user-game-card
          :game="game"
        ></user-game-card>
      </v-col>
    </v-row>
    <v-card-title v-if="games.length == 0">
      Ei vielä pelejä
    </v-card-title>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserGameCard from '../components/tiimi_admin/league/game/UserGameCard.vue'

export default {
  components: { UserGameCard },
  data: () => ({
    season: null
  }),
  created() {
    this.season = new Date().getMonth() + 1 <= this.currentTeam.season_start_month ? new Date().getFullYear() - 1 : new Date().getFullYear()

    let q = this.$route.query.season
    if(q) {
      this.season = parseInt(q)
      this.initGames({season: this.season})
    } else {
      this.initGames({season: this.season})
    }

    
  },
  methods: {
    ...mapActions('admin', [
      'initGames'
    ]),
    changeSeason(season) {
      this.$router.replace({ query: {season: season }})
      this.initGames({season})
    }
  },
  computed: {
    ...mapGetters('admin', [
      'games'
    ]),
    ...mapGetters('user', [
      'currentTeam'
    ])
  }
}
</script>