<template>
  <v-card  @click="$router.push(`/games/${game.id}`)">
    <v-row class="px-4 pt-4" align-content="space-around">
      <v-img
        :src="game.home_team_logo_url"
        height="70"
        width="70"
        contain
      ></v-img>
      <v-img
        :src="game.away_team_logo_url"
        height="70"
        width="70"
        contain
      ></v-img>
    </v-row>
    <v-row class="px-4" align-content="center"> 
       <v-col> <v-card-title style="justify-content: center;">{{game.home_team_short_name}}</v-card-title> </v-col>
       <v-col> <v-card-title style="justify-content: center;">{{game.away_team_short_name}}</v-card-title> </v-col>
    </v-row>
    <div style="text-align: center; margin-top: -58px;">
      <v-chip class="pt-0">
        {{game.score_home}} - {{game.score_away}}
      </v-chip>
    </div>
    <div class="mt-4 pb-3" style="text-align: center;">
      {{prettyDate}}
    </div>
    <!-- {{game}} -->
  </v-card>
</template>


<script>
export default {
  props: ['game'],
  computed: {
    prettyDate() {
      const date = new Date(this.game.starttime_unix)
      return date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear()
    }
  }
}
</script>